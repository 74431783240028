.rounded-3-sides {
  border-radius: 9px 9px 0px 9px;
}

.custom-rounded {
  border-radius: 9px;
}

.trips::-webkit-scrollbar {
  width: 7px;
}

.trips::-webkit-scrollbar-track {
  background: transparent;
}

.trips::-webkit-scrollbar-thumb {
  background: rgba(135, 135, 135, 0.8);
  border-radius: 12px;
}

.trips::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 33%);
}

.arrow-bottom {
  display: none;
}

.trips-info {
  position: fixed;

  left: 40px;
}

@media (max-width: 992px) {
  .trips-info {
    width: 100%;
    position: fixed !important;
    bottom: 0;
    left: 0;
  }
  .arrow-bottom {
    display: block;
  }
}

@media (max-width: 992px) {
  .close-collapse {
    height: 0;
    overflow: hidden;
  }

  .open-collapse {
    height: 150px;
  }
}
