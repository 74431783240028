.ul-nav li {
  color: var(--seconder-color);
}

.ul-nav a {
  text-decoration: none;
  margin-top: 0;
}

.ul-nav-2 a {
  color: var(--primary-color) !important;
  text-decoration: none;
  position: relative;
  padding-bottom: 5px;
}

.ul-nav-2 .active::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 30px;
  background-color: #f2b35b;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.ul-nav-2 .active{
  width: fit-content;
}

.navbar-toggler.collapsed {
  border: none;
}

@media (max-width: 992px) {
  .ul-nav a,
  .ul-nav-2 a {
    display: block;
    margin-top: 10px;
  }

  .logo {
    width: 80px;
  }
}
