.accordion-header{
  background: transparent !important;
  
}
.accordion-header button {
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px !important;
  color: #01397a !important;
  text-align: right !important;
}
.accordion-header button::after{
  margin-left: 0 !important;
}

@media (max-width: 768px) {
  .accordion-header button{
    font-size: 28px !important;
  }
}

