.landing {
  background-image: url("../../Assets/images/background1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-inputs input,
.landing-inputs select,
.landing-inputs .cutsom-date {
  border: 0 !important;
  padding: 10px !important;
}

@media (min-width: 768px) {
  .cutsom-date {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .landing-input-2 {
    border-radius: 0 !important;
  }
}
.landing-input-3 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media (max-width: 768px) {
  .landing-input-2 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.custom-radio {
  width: 18px;
  height: 18px;
}

.custom-radio-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
