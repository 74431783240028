.form-control.is-invalid, .was-validated .form-control:invalid{
  background-position: left calc(0.375em + 0.1875rem) center !important;
  border-width: 3px;
}
.form-select.is-valid, .was-validated .form-select:valid, .form-control.is-valid, .was-validated .form-control:valid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
  border-width: 3px
}
.top-35 {
  top: 35% !important;
}