@font-face {
  font-family: "arabicFont";
  src: url("./Assets/Fonts/ArbFONTS-bader_al-gordabia.ttf");
}
:root {
  --primary-color: #01397a;
  --primary-color-op: rgba(0, 56, 120, 0.8);
  --seconder-color: #34aae1;
  --orange-color: #ffb133;
  --payment-color: #011c37;
}
body {
  font-family: "arabicFont", sans-serif !important;
}
.bg-gray {
  background-color: #efefef !important;
}
.bg-custom-gray {
  background-color: #747474 !important;
}
.bg-gold {
  background-color: var(--orange-color) !important;
}
.gold {
  color: var(--orange-color) !important;
}
.orange {
  color: #ffa926 !important;
}

.bg-custom-primary {
  background-color: var(--primary-color) !important;
}
.bg-custom-primary-opacity {
  background-color: var(--primary-color-op) !important;
}
.bg-seconde {
  background-color: var(--seconder-color) !important;
}
.bg-blue {
  background-color: var(--payment-color) !important;
}
.primary {
  color: var(--primary-color) !important;
}

.seconde {
  color: var(--seconder-color) !important;
}
.w-fit-content {
  width: fit-content !important;
}
.f-eng {
  font-family: "Roboto", sans-serif !important;
}

/* Custom Bootstrap */
.vh-md-100 {
  min-height: 100vh;
}
.min-vh-75 {
  min-height: 75vh;
}
.fs-7 {
  font-size: 13px !important;
}
@media (max-width: 992px) {
  .fs-7 {
    font-size: 11px !important;
  }
}
@media (max-width: 768px) {
  .fs-7 {
    font-size: 9px !important;
  }
}
.vh-md-100 {
  min-height: 100vh;
}

/* Backgrounds */

.bg-url {
  background-image: url("./Assets//images/background1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.bg-url-without {
  background-image: url("./Assets//images/background1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.bg-url::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.8;
}

.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.8);
}

/* Borders */
.border-bttom-opacity {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* Transforms */

.rotate-90 {
  rotate: -90deg;
}

/* Cursor */
.pointer {
  cursor: pointer;
}
