.rounded-form {
  border-radius: 12px !important;
}
.rounded-form-start {
  border-radius: 12px 0 0 12px !important;
}
.rounded-form-end {
  border-radius: 0 12px 12px 0 !important;
}
.shadow-form {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}
.custom-form::placeholder {
  color: #d8d8d8 !important;
  font-weight: 600;
}
.label-check label {
  font-size: 24px;
  font-weight: bold;
}
.label-check input {
  margin: 0.4rem;
  width: 25px;
  height: 25px;
}

.form-check-input:checked[type="radio"] {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}
.placeholder-color::placeholder {
  color: #a8aaaa;
}
