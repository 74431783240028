#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  translate: 0% -50%;
  position: absolute;
  animation: loading-bar-spinner 550ms linear infinite;
  -webkit-animation: loading-bar-spinner 550ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 22px;
  height: 22px;
  border: solid 4px transparent;
  border-top-color: white !important;
  border-left-color: white !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    rotate: 0deg;
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
    rotate: 360deg;
  }
}
