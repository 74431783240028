.chair {
  width: 35px;
}

.chair-img {
  width: 35px;
}

.chair-nums {
  transform: translate(-50%, -60%) rotate(90deg);
  -webkit-transform: translate(-50%, -60%) rotate(90deg);
  -moz-transform: translate(-50%, -60%) rotate(90deg);
  -ms-transform: translate(-50%, -60%) rotate(90deg);
  -o-transform: translate(-50%, -60%) rotate(90deg);
}

.w-custom {
  width: 35px;
}

.w-300 {
  width: 300px;
}

.chairs-pos h4 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 18px;
  left: 28px;
  font-size: 16px;
}

@media (min-width: 992px) {
  .chairs-pos {
    position: absolute;
    top: 50%;
    left: 0%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(-90deg);
    -webkit-transform: translate(-50%, -50%) rotate(-90deg);
    -moz-transform: translate(-50%, -50%) rotate(-90deg);
    -ms-transform: translate(-50%, -50%) rotate(-90deg);
    -o-transform: translate(-50%, -50%) rotate(-90deg);
  }
  .chairs-pos h4 {
    position: absolute;
    transform: translate(-10%, -50%) rotate(90deg);
    top: 50%;
    left: 42px;
  }
  .chair {
    transform: rotate(90deg) scaleX(-1);
    -webkit-transform: rotate(90deg) scaleX(-1);
    -moz-transform: rotate(90deg) scaleX(-1);
    -ms-transform: rotate(90deg) scaleX(-1);
    -o-transform: rotate(90deg) scaleX(-1);
  }
  .chair-img {
    transform: rotate(90deg) scaleX(-1);
    -webkit-transform: rotate(90deg) scaleX(-1);
    -moz-transform: rotate(90deg) scaleX(-1);
    -ms-transform: rotate(90deg) scaleX(-1);
    -o-transform: rotate(90deg) scaleX(-1);
  }
}

@media (max-width: 992px) {
  .chairs-height {
    height: 540px;
  }
  .chair {
    width: 30px;
  }
  .chair-img {
    width: 30px;
  }
  .chair-nums {
    transform: translate(-50%, -50%) rotate(0);
    -webkit-transform: translate(-50%, -50%) rotate(0);
    -moz-transform: translate(-50%, -50%) rotate(0);
    -ms-transform: translate(-50%, -50%) rotate(0);
    -o-transform: translate(-50%, -50%) rotate(0);
  }
  .w-custom {
    width: 30px;
  }
}
